import React, { useState, useEffect, useRef } from "react"
import logo from "./logo.svg"
import "./App.css"
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { ord, ordNumber } from "fp-ts/lib/Ord"
import * as Arr from "fp-ts/lib/Array"
import { pipe } from "fp-ts/lib/pipeable"
import * as Either from "fp-ts/lib/Either"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faLock,
  faCross,
  faTimes,
  faEdit,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import { DateFromISOString as date } from "io-ts-types/lib/DateFromISOString"
import { AssertionError } from "assert"
import "react-datepicker/dist/react-datepicker.css"
import Modal from "react-modal"
import * as t from "io-ts"
import * as tPromise from "io-ts-promise"
import { Lens } from "monocle-ts"
import { PathReporter } from "io-ts/lib/PathReporter"
import { navigate } from "./Utils/Routing"
import moment from "moment"
import { it } from "date-fns/locale"
import { useFetch } from "./Utils/Fetch"
import LoadingSpinner from "./Components/Loading/Spinner"
registerLocale("it", it)
setDefaultLocale("it")
const DATE_OFFSET = new Date().getTimezoneOffset()
const DATE_FORMAT = "DD/MM/YYYY HH:mm"

function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}
const deinternationalize = (d: Date) =>
  new Date(d.toLocaleString().replace(/ GMT.+/, ""))

const formatNumber = (n: number) =>
  (Math.round((n + Number.EPSILON) * 100) / 100).toFixed(2).toLocaleString()
function assert(x: any): asserts x {
  if (x) {
    return
  } else {
    throw new AssertionError({ message: "assertion failed" })
    convertDateToUTC(new Date())
  }
}

function* range(
  from: number,
  to: number,
  step: number | undefined = undefined
) {
  step = step ?? (to > from ? 1 : -1)
  for (let i = from; i !== to; i += step) {
    yield i
  }
}

function clamp(x: number, low: number, high: number) {
  return Math.min(Math.max(x, low), high)
}
function padLeft(padding: string, val: string) {
  return (padding + val).slice(-padding.length)
}

type PageItem = number | "separator"

const scrollToRef = (ref: React.MutableRefObject<any>) =>
  window.scrollTo(0, ref.current.offsetTop)

function paginationRange(currentPage: number, lastPage: number): PageItem[] {
  const showPrevious = currentPage !== 1
  const showNext = currentPage !== lastPage
  const previous = showPrevious
    ? [
        ...range(currentPage - 1, clamp(currentPage - 4, 1, Infinity), -1),
      ].reverse()
    : []
  const next = showNext
    ? [...range(currentPage + 1, clamp(currentPage + 4, 0, lastPage), 1)]
    : []
  const first =
    showPrevious && !previous.includes(1) ? [1, "separator" as const] : []
  const last =
    showNext && !next.includes(lastPage) ? ["separator" as const, lastPage] : []
  const r = [...first, ...previous, currentPage, ...next, ...last]
  return r
}
type Filtro = { name: string } & (
  | { type: "CheckboxList"; values: string[] }
  | { type: "Date" }
  | { type: "Textbox" }
)

const option = <C extends t.Mixed>(codec: C) =>
  t.union([t.undefined, t.null, codec])

const Punto = t.type({
  punto: t.type({
    numeroPunto: t.number,
    nomePunto: option(t.string),
  }),
})
const Destinazione = t.union([t.literal("MagazzinoWeb"), Punto])
type Punto = t.TypeOf<typeof Punto>
type Destinazione = t.TypeOf<typeof Destinazione>

const StatoOrdine = t.keyof({
  Nuovo: null,
  Ricevuto: null,
  InizioEvasione: null,
  InPicking: null,
  Chiuso: null,
  Consegnato: null,
  Spedito: null,
  Cancellato: null,
})
type StatoOrdine = t.TypeOf<typeof StatoOrdine>

const statiOrdine = Object.keys(StatoOrdine.keys)

const OrdineTdc = t.type({
  codiceOrdine: t.string,
  dataOraRegistrazioneOrdine: t.string,
  numeroPunto: t.number,
  nomePunto: option(t.string),
  idTessera: option(t.string),
  cliente: t.string,
  provincia: t.string,
  inviatoAZeus: t.boolean,
  inviatoADedalo: t.boolean,
  importo: t.number,
  numeroRighe: t.number,
  statoOrdine: StatoOrdine,
  provenienza: t.keyof({
    ECommerceNazionale: null,
    CliccaERitira: null,
  }),
  forzaInvioZeus: option(t.string),
  forzaInvioDedalo: option(t.string),
})
type OrdineTdc = t.TypeOf<typeof OrdineTdc>

const FiltroProvenienza = t.keyof({
  ECommerceNazionale: null,
  CliccaERitira: null,
  Tutto: null,
})
type FiltroProvenienza = t.TypeOf<typeof FiltroProvenienza>

const provenienzaDefault: FiltroProvenienza = "Tutto"

const FiltroPeriodo = t.type({
  inizio: option(date),
  fine: option(date),
})
type FiltroPeriodo = t.TypeOf<typeof FiltroPeriodo>
const periodoDefault: FiltroPeriodo = { inizio: undefined, fine: undefined }

const Filtri = t.type({
  provenienza: t.array(FiltroProvenienza),
  destinazione: t.array(Destinazione),
  periodo: FiltroPeriodo,
  stato: t.array(StatoOrdine),
  codiceOrdine: option(t.string),
})

type Filtri = t.TypeOf<typeof Filtri>

const Blocchi = t.type({
  provenienza: t.array(FiltroProvenienza),
  destinazione: t.array(Destinazione),
  periodo: FiltroPeriodo,
  totale: t.boolean,
})
const BlocchiDTO = t.type({
  provenienza: option(FiltroProvenienza),
  destinazione: t.array(Destinazione),
  periodo: FiltroPeriodo,
  totale: t.boolean,
})

type Blocchi = t.TypeOf<typeof Blocchi>

const TOTALE = "TOTALE"
const PARZIALE = "PARZIALE"
const NON_ATTIVO = "NON_ATTIVO"
type StatoBlocchi = typeof TOTALE | typeof PARZIALE | typeof NON_ATTIVO
function checkBlocchi(x: Blocchi): StatoBlocchi {
  if (!x) return NON_ATTIVO
  if (x.totale) {
    return TOTALE
  }
  if (
    x.provenienza.length === 0 ||
    !x.provenienza.includes("Tutto") ||
    x.destinazione.length !== 0 ||
    x.periodo.inizio ||
    x.periodo.fine
  ) {
    return PARZIALE
  }
  return NON_ATTIVO
}

const filtriDefault: Filtri = {
  provenienza: [],
  destinazione: [],
  periodo: periodoDefault,
  stato: [],
  codiceOrdine: undefined,
}

const urlPrefix =
  window.location.hostname.includes("staging") ||
  window.location.hostname.includes("localhost")
    ? ".staging"
    : ""
const BASE_URL = `https://api${urlPrefix}.futurelab.cloud/cliccaritira/v2/tdc`

function onError<T = any>(e: t.Errors): T {
  throw new Error(`[${PathReporter.report(Either.left(e)).join(",")}]`)
}

const url = (path: string, root: string = BASE_URL) => `${root}/${path}`

const sortDestinazioni = (destinazioni: Destinazione[]) => {
  const magazzinoWebInCima = destinazioni.sort()
  const magazzinoweb = magazzinoWebInCima[0]
  assert(magazzinoweb === "MagazzinoWeb")

  const rest = magazzinoWebInCima.slice(1) as Punto[]

  const byPunto = ord.contramap(ordNumber, (x: Punto) => x.punto.numeroPunto)
  return [magazzinoweb, ...Arr.sortBy([byPunto])(rest)]
}

const fetchUrl = async (path: string, root: string = BASE_URL) => {
  const acc_token = localStorage.getItem("access_token")
  const res = await fetch(`${root}/${path}`, {
    headers: { Authorization: `Bearer ${acc_token}` },
  })
  if (res.ok) {
    return res
  } else {
    throw new Error(`path: ${path}, status: ${res.status}`)
  }
}
const deleteUrl = async (path: string, root: string = BASE_URL) => {
  const acc_token = localStorage.getItem("access_token")
  const res = await fetch(`${root}/${path}`, {
    headers: { Authorization: `Bearer ${acc_token}` },
    method: "DELETE",
  })
  if (res.ok) {
    return res
  } else {
    throw new Error(`${res.status}`)
  }
}
const postUrl = async (path: string, body: any, root: string = BASE_URL) => {
  const acc_token = localStorage.getItem("access_token")
  const res = await fetch(`${root}/${path}`, {
    headers: {
      Authorization: `Bearer ${acc_token}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  })
  if (res.ok) {
    return res
  } else {
    const body = await res.text()
    throw new Error(
      `{ status: ${res.status}, response: ${body}, statusText: ${res.statusText}}`
    )
  }
}
const App: React.FC = function App() {
  const [destinazioni, setDestinazioni] = useState<string[]>([])
  const [destObjs, setDestObjs] = useState<Destinazione[]>([])
  const [filtri, setFiltri] = useState(filtriDefault)
  const [ordini, setOrdini] = useState([] as OrdineTdc[])
  const [paginaCorrente, setPaginaCorrente_] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [primoCaricamento, setPrimoCaricamento] = useState(true)
  const setPaginaCorrente = (p: number) => {
    setPaginaCorrente_(p)
    setIndiceSelezionato(undefined)
  }
  const [numeroPagine, setNumeroPagine] = useState(1)
  const [premutoApplica, setApplica] = useState(false)
  const applica = () => {
    setIsLoading(true)
    setApplica((x) => !x)
  }

  const [blocchi, setBlocchi] = useFetch(async () => {
    const dati = await fetchUrl("blocchi")
    const json = await dati.json()
    const blocchi = await tPromise.decode(BlocchiDTO, json)
    return { ...blocchi, provenienza: [blocchi.provenienza] } as Blocchi
  })

  const [indiceSelezionato, setIndiceSelezionato] = useState(
    undefined as undefined | number
  )
  useEffect(() => {
    const acc_token = localStorage.getItem("access_token")
    if (acc_token == null) {
      navigate("/")
    }
  }, [])
  useEffect(() => {
    ;(async () => {
      const dati = await fetchUrl(`destinazioni`)
      const json = await dati.json()
      const destinazioni = t.array(Destinazione).decode(json)
      const onOk = (e: Destinazione[]): Destinazione[] => e

      const result: Destinazione[] = pipe(
        destinazioni,
        Either.fold(onError, onOk)
      )
      const destSorted = sortDestinazioni(result)
      setDestObjs(destSorted)
      setDestinazioni(
        destSorted.map((x) =>
          typeof x === "string"
            ? x
            : x.punto.nomePunto || `Punto ${x.punto.numeroPunto}`
        )
      )
    })()
  }, [])
  useEffect(() => {
    let filtriModificati = {
      ...filtri,
      provenienza:
        filtri.provenienza.length === 1 ? filtri.provenienza[0] : "Tutto",
      periodo: {
        inizio:
          filtri.periodo.inizio == null
            ? undefined
            : moment(filtri.periodo.inizio).subtract(DATE_OFFSET, "minutes"),
        fine:
          filtri.periodo.fine == null
            ? undefined
            : moment(filtri.periodo.fine).subtract(DATE_OFFSET, "minutes"),
      },
    }
    if (primoCaricamento) {
      setPrimoCaricamento(false)
      setIsLoading(false)
      return
    }
    ;(async () => {
      const dati = await postUrl(
        `ordini/${paginaCorrente - 1}`,
        filtriModificati
      )
      const result = await tPromise.decode(
        t.array(OrdineTdc),
        await dati.json()
      )
      setOrdini(result)
      setIsLoading(false)
    })()
    ;(async () => {
      const pagine = await postUrl(`npagine`, filtriModificati)
      const json = await pagine.json()
      const npagine = await tPromise.decode(t.number, json)
      setNumeroPagine(npagine)
    })()
  }, [premutoApplica])

  const startDate = filtri.periodo.inizio
  const setDate = (path: "inizio" | "fine") => (d: Date | null) => {
    d && setFiltri(Lens.fromPath<Filtri>()(["periodo", path]).set(d))
  }
  const endDate = filtri.periodo.fine
  const graficaFiltri: Filtro[] = [
    { name: "Numero ordine", type: "Textbox" },
    {
      name: "Provenienza",
      type: "CheckboxList",
      values: ["ECommerceNazionale", "CliccaERitira"],
    },
    { name: "Destinazione", type: "CheckboxList", values: destinazioni },
    { name: "Periodo", type: "Date" },
    { name: "Stato", type: "CheckboxList", values: statiOrdine },
  ]
  const getCodiceOrdine = (cod: string) => {
    try {
      const parsed = JSON.parse(cod)
      if (typeof parsed === "object" && parsed.codiceOrdine != null) {
        return parsed.codiceOrdine
      } else return cod
    } catch {
      return cod
    }
  }
  const onSelectedProvenienza = (name: string, value: boolean) => {
    if (value) {
      setFiltri({
        ...filtri,
        provenienza: [...filtri.provenienza, name as any],
      })
    } else {
      setFiltri({
        ...filtri,
        provenienza: filtri.provenienza.filter((d) => d !== name),
      })
    }
  }
  const checkedProvenienza = (name: string) =>
    filtri.provenienza.includes(name as any)
  const destinazioneSelector = (name: string) => (x: Destinazione) =>
    (typeof x === "string" && x === name) ||
    (typeof x === "object" &&
      (x.punto.nomePunto === name || name === `Punto ${x.punto.numeroPunto}`))

  const onSelectedDestinazione = (name: string, value: boolean) => {
    if (value) {
      const dest = destObjs.find(destinazioneSelector(name))!
      setFiltri({
        ...filtri,
        destinazione: [...filtri.destinazione, dest],
      })
    } else {
      setFiltri({
        ...filtri,
        destinazione: filtri.destinazione.filter(
          (d) => !destinazioneSelector(name)(d)
        ),
      })
    }
  }
  const checkedDestinazione = (name: string) =>
    filtri.destinazione.findIndex(destinazioneSelector(name)) !== -1
  const onSelectedStato = (name: string, value: boolean) => {
    if (value) {
      setFiltri({
        ...filtri,
        stato: [...filtri.stato, name as any],
      })
    } else {
      setFiltri({
        ...filtri,
        stato: filtri.stato.filter((d) => d !== name),
      })
    }
  }
  const checkedStato = (name: string) => filtri.stato.includes(name as any)

  const coloreFromBlocco = (x: Blocchi) => {
    const status = checkBlocchi(x)
    switch (status) {
      case NON_ATTIVO:
        return "green"
      case PARZIALE:
        return "#fdca40"
      case TOTALE:
        return "red"
    }
  }
  const onChangeCodice = (codice: string) => {
    setFiltri({
      ...filtri,
      codiceOrdine: codice,
    })
  }

  const [modalBlocco, setModalBlocco] = useState(false)
  const openModalBlocco = () => setModalBlocco(true)
  const closeModalBlocco = () => setModalBlocco(false)

  const [modalRegole, setModalRegole] = useState(false)
  const openModalRegole = () => setModalRegole(true)
  const closeModalRegole = () => setModalRegole(false)

  const [modalLog, setModalLog] = useState(false)
  const openModalLog = () => setModalLog(true)
  const closeModalLog = () => setModalLog(false)

  const [modalDettaglio, setModalDettaglio] = useState(false)
  const openModalDettaglio = () => setModalDettaglio(true)
  const closeModalDettaglio = () => setModalDettaglio(false)

  const [modalForzaInvio, setModalForzaInvio] = useState(false)
  const openModalForzaInvio = () => setModalForzaInvio(true)
  const closeModalForzainvio = () => setModalForzaInvio(false)

  const showDedalo = () =>
    indiceSelezionato != null &&
    ordini[indiceSelezionato].provenienza === "ECommerceNazionale"

  const logout = () => {
    localStorage.removeItem("access_token")
    navigate("/")
  }

  return (
    <>
      <Modal
        ariaHideApp={false}
        className="react-modal modal-r zoomIn"
        overlayClassName="react-overlay"
        shouldCloseOnOverlayClick={false}
        isOpen={modalBlocco}
        onRequestClose={(_) => closeModalBlocco()}
      >
        <ModalBody
          destinazioni={destObjs}
          blocchi={blocchi}
          destinazioniString={destinazioni}
          close={closeModalBlocco}
          onConfirm={setBlocchi}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        className="react-modal modal-r zoomIn"
        overlayClassName="react-overlay"
        shouldCloseOnOverlayClick={false}
        isOpen={modalRegole}
        onRequestClose={(_) => closeModalRegole()}
      >
        <Regole close={closeModalRegole} destinazioni={destObjs}></Regole>
      </Modal>
      <Modal
        ariaHideApp={false}
        className="react-modal modal-r zoomIn"
        overlayClassName="react-overlay"
        shouldCloseOnOverlayClick={false}
        isOpen={indiceSelezionato != null && modalLog}
        onRequestClose={(_) => closeModalLog()}
      >
        {indiceSelezionato != null && (
          <LogBody
            close={closeModalLog}
            codiceOrdine={ordini[indiceSelezionato!].codiceOrdine}
          ></LogBody>
        )}
      </Modal>
      <Modal
        ariaHideApp={false}
        className="react-modal modal-r-large zoomIn"
        overlayClassName="react-overlay"
        shouldCloseOnOverlayClick={false}
        isOpen={indiceSelezionato != null && modalDettaglio}
        onRequestClose={(_) => closeModalDettaglio()}
      >
        {indiceSelezionato != null && (
          <Dettaglio
            close={closeModalDettaglio}
            codiceOrdine={ordini[indiceSelezionato!].codiceOrdine}
          />
        )}
      </Modal>
      <Modal
        ariaHideApp={false}
        className="react-modal modal-r-small zoomIn"
        overlayClassName="react-overlay"
        shouldCloseOnOverlayClick={false}
        isOpen={indiceSelezionato != null && modalForzaInvio}
        onRequestClose={(_) => closeModalForzainvio()}
      >
        <ConfermaForzaInvio
          showDedalo={showDedalo()}
          close={closeModalForzainvio}
          codiceOrdine={
            indiceSelezionato == null
              ? ""
              : ordini[indiceSelezionato].codiceOrdine
          }
          ultimoForzaInvio={
            indiceSelezionato == null
              ? {}
              : {
                  zeus: ordini[indiceSelezionato].forzaInvioZeus || undefined,
                  dedalo:
                    ordini[indiceSelezionato].forzaInvioDedalo || undefined,
                }
          }
        />
      </Modal>
      <nav className="navbar is-white">
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item brand-text is-size-4" href="/home">
              Torre di controllo BETA
            </a>
          </div>
        </div>
      </nav>
      {/* END NAV */}
      <div className="container">
        <div className="columns">
          <div className="column is-3">
            <aside className="menu is-hidden-mobile">
              <div className="columns">
                <div className="column">
                  <p className="menu-label">Filtri</p>
                </div>
                <div className="column" style={{ paddingRight: "0px" }}>
                  <button
                    className="button is-pulled-right"
                    onClick={(_) => {
                      setPaginaCorrente(1)
                      applica()
                    }}
                  >
                    Applica
                  </button>
                  <button
                    style={{ marginRight: "2px" }}
                    className="button is-pulled-right"
                    onClick={(_) => {
                      setFiltri(filtriDefault)
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <SezioneFiltri
                filtri={graficaFiltri}
                checked={{
                  destinazione: checkedDestinazione,
                  provenienza: checkedProvenienza,
                  stato: checkedStato,
                }}
                selected={{
                  destinazione: onSelectedDestinazione,
                  provenienza: onSelectedProvenienza,
                  stato: onSelectedStato,
                }}
                date={{
                  inizio: startDate || undefined,
                  fine: endDate || undefined,
                  onChangeInizio: setDate("inizio"),
                  onChangeFine: setDate("fine"),
                }}
                textbox={{
                  onChange: onChangeCodice,
                  value: filtri.codiceOrdine || "",
                }}
              />
            </aside>
          </div>
          <div className="column is-9">
            <section className="hero is-info welcome is-small">
              <div className="hero-body">
                <div className="row container">
                  <button
                    className="button has-background-white has-full-alpha topbar-button"
                    onClick={(_) => openModalForzaInvio()}
                  >
                    Forza Invio
                  </button>
                  <button
                    className="button has-background-white has-full-alpha topbar-button"
                    onClick={(_) => openModalLog()}
                  >
                    Log
                  </button>
                  <Icon
                    icon={faCog}
                    className="topbar-icon is-clickable topbar-button"
                    onClick={(_) => {
                      /* openModalRegole() */
                    }}
                  ></Icon>
                  <Icon
                    icon={faLock}
                    className="topbar-icon is-clickable topbar-button"
                    style={{ color: coloreFromBlocco(blocchi) }}
                    onClick={(_) => openModalBlocco()}
                  ></Icon>
                  <button
                    className="button has-background-white is-pulled-right has-full-alpha topbar-button"
                    onClick={(_) => logout()}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </section>
            <section className="info-tiles section-ordini">
              <table className="table is-striped" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Numero ordine</th>
                    <th>Data</th>
                    <th>Destinazione</th>
                    <th>Cliente</th>
                    <th>Provincia</th>
                    <th>Stato</th>
                    <th>
                      <abbr title="inviato a ZEUS">ZEUS</abbr>
                    </th>
                    <th>
                      <abbr title="inviato a DEDALO">Dedalo</abbr>
                    </th>
                    <th>Importo</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Numero ordine</th>
                    <th>Data</th>
                    <th>Destinazione</th>
                    <th>Cliente</th>
                    <th>Provincia</th>
                    <th>Stato</th>
                    <th>
                      <abbr title="inviato a ZEUS">ZEUS</abbr>
                    </th>
                    <th>
                      <abbr title="inviato a DEDALO">Dedalo</abbr>
                    </th>
                    <th>Importo</th>
                  </tr>
                </tfoot>
                <tbody>
                  {/* <tr>
                    <tr>1</tr>
                    <tr>30/03/2020</tr>
                    <tr>Cliente 1</tr>
                    <tr>VI</tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                  </tr> */}
                  {/* {range(15).map(i => (
                    <tr>
                      <td>{padLeft("0000000000", i.toString())}</td>
                      <td>{i}/04/2020</td>
                      <td>Cliente {i}</td>
                      <td>VI</td>
                      <td>Stato Test</td>
                      <td>{i % 2 === 0 ? "Si" : "No"}</td>
                      <td>{i % 2 !== 0 ? "Si" : "No"}</td>
                      <td>{Math.round(100 * (Math.random() * 30 + 1)) / 100}</td>
                    </tr>
                  ))} */}
                  {isLoading && (
                    <div className="container has-text-centered">
                      <LoadingSpinner />
                    </div>
                  )}
                  {!isLoading &&
                    ordini.map((ordine, i) => (
                      <tr
                        className={
                          indiceSelezionato === i ? "ordine-selected" : ""
                        }
                        onClick={(_) => setIndiceSelezionato(i)}
                        onDoubleClick={(_) => openModalDettaglio()}
                      >
                        <td>{ordine.codiceOrdine}</td>
                        <td>
                          {moment(ordine.dataOraRegistrazioneOrdine).format(
                            DATE_FORMAT
                          )}
                        </td>
                        <td>{ordine.nomePunto}</td>
                        <td>{ordine.cliente}</td>
                        <td>{ordine.provincia}</td>
                        <td>{ordine.statoOrdine}</td>
                        <td>{ordine.inviatoAZeus ? "Si" : "No"}</td>
                        <td>{ordine.inviatoADedalo ? "Si" : "No"}</td>
                        <td style={{ textAlign: "right" }}>
                          {formatNumber(ordine.importo)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </section>
            <div
              className="pagination"
              role="pagination"
              aria-label="pagination"
            >
              <ul className="pagination-list">
                {numeroPagine !== 0 &&
                  ordini.length > 0 &&
                  paginationRange(paginaCorrente, numeroPagine).map((x) => (
                    <li>
                      {x === "separator" ? (
                        <span className="pagination-ellipsis">&hellip;</span>
                      ) : (
                        <span
                          className={`pagination-link ${
                            x === paginaCorrente ? "has-background-primary" : ""
                          }`}
                          onClick={(_) => {
                            setPaginaCorrente(x)
                            applica()
                          }}
                        >
                          {x}
                        </span>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default App

interface CheckedCallbacks {
  destinazione: (_: string) => boolean
  stato: (_: string) => boolean
  provenienza: (_: string) => boolean
}
interface SelectedCallbacks {
  destinazione: (name: string, checked: boolean) => void
  stato: (name: string, checked: boolean) => void
  provenienza: (name: string, checked: boolean) => void
}

interface DateProps {
  inizio?: Date
  fine?: Date
  onChangeInizio: (_: Date) => void
  onChangeFine: (_: Date) => void
}

interface TextboxProps {
  onChange: (val: string) => void
  value: string
}
interface FiltriProps {
  filtri: Filtro[]
  checked: CheckedCallbacks
  selected: SelectedCallbacks
  date: DateProps
  insideModal?: boolean
  textbox: TextboxProps
}

const SezioneFiltri: React.FC<FiltriProps> = ({
  filtri,
  checked,
  selected,
  date,
  insideModal = false,
  textbox,
}) => {
  const classes = insideModal ? "custom-box" : "box"
  return (
    <>
      {filtri.map((x) => (
        <>
          <p className="is-size-5">{x.name}</p>
          <div className={classes}>
            {x.type === "CheckboxList" &&
              x.values.map((val) => (
                <div className="row">
                  <label className="custom-container">
                    {val}
                    <input
                      type="checkbox"
                      name={val}
                      checked={
                        x.name === "Destinazione"
                          ? checked.destinazione(val)
                          : x.name === "Stato"
                          ? checked.stato(val)
                          : x.name === "Provenienza"
                          ? checked.provenienza(val)
                          : false
                      }
                      onChange={(evt) =>
                        x.name === "Destinazione"
                          ? selected.destinazione(
                              evt.target.name,
                              evt.target.checked
                            )
                          : x.name === "Stato"
                          ? selected.stato(evt.target.name, evt.target.checked)
                          : x.name === "Provenienza"
                          ? selected.provenienza(
                              evt.target.name,
                              evt.target.checked
                            )
                          : undefined
                      }
                    />
                    <span className="custom-checkmark"></span>
                  </label>
                </div>
              ))}
            {x.type === "Date" && (
              <>
                <div className="row date-row">
                  <span>Da:</span>
                  <DatePicker
                    className="input"
                    selected={date.inizio}
                    onChange={date.onChangeInizio}
                    selectsStart
                    dateFormat="dd/MM/yyyy"
                    startDate={date.inizio}
                    endDate={date.fine}
                  />
                </div>
                <div className="row date-row">
                  <span>A:</span>
                  <DatePicker
                    className="input"
                    selected={date.fine}
                    onChange={date.onChangeFine}
                    selectsEnd
                    dateFormat="dd/MM/yyyy"
                    startDate={date.inizio}
                    endDate={date.fine}
                    minDate={date.inizio}
                  />
                </div>
              </>
            )}
            {x.type === "Textbox" && (
              <input
                type="text"
                className="login-input"
                value={textbox.value}
                onChange={(x) => textbox.onChange(x.target.value)}
              />
            )}
          </div>
          {x.type !== "Date" && insideModal && <hr className="hr" />}
        </>
      ))}
    </>
  )
}

interface ModalProps {
  destinazioni: Destinazione[]
  destinazioniString?: string[]
  close: () => void
  blocchi: Blocchi
  onConfirm: (_: Blocchi) => void
}
const ModalBody: React.FC<ModalProps> = ({
  destinazioni,
  destinazioniString: d,
  close,
  blocchi,
  onConfirm,
}) => {
  const destinazioniString =
    d ||
    destinazioni.map((x) =>
      typeof x === "string"
        ? x
        : x.punto.nomePunto || `Punto ${x.punto.numeroPunto}`
    )

  const [filtri, setFiltri] = useState(blocchi)

  const onSelectedProvenienza = (name: string, value: boolean) => {
    if (value) {
      setFiltri({
        ...filtri,
        provenienza: [
          ...filtri.provenienza.filter((x) => x !== "Tutto"),
          name as any,
        ],
      })
    } else {
      const newProv = filtri.provenienza.filter((d) => d !== name)
      setFiltri({
        ...filtri,
        provenienza: newProv.length === 0 ? ["Tutto"] : newProv,
      })
    }
  }
  const checkedProvenienza = (name: string) =>
    filtri.provenienza.includes(name as any)
  const destinazioneSelector = (name: string) => (x: Destinazione) =>
    (typeof x === "string" && x === name) ||
    (typeof x === "object" &&
      (x.punto.nomePunto === name || name === `Punto ${x.punto.numeroPunto}`))

  const onSelectedDestinazione = (name: string, value: boolean) => {
    if (value) {
      const dest = destinazioni.find(destinazioneSelector(name))!
      setFiltri({
        ...filtri,
        destinazione: [...filtri.destinazione, dest],
      })
    } else {
      setFiltri({
        ...filtri,
        destinazione: filtri.destinazione.filter(
          (d) => !destinazioneSelector(name)(d)
        ),
      })
    }
  }
  const checkedDestinazione = (name: string) =>
    filtri.destinazione.findIndex(destinazioneSelector(name)) !== -1

  const graficaFiltri: Filtro[] = [
    {
      name: "Provenienza",
      type: "CheckboxList",
      values: ["ECommerceNazionale", "CliccaERitira"],
    },
    { name: "Destinazione", type: "CheckboxList", values: destinazioniString },
    { name: "Periodo", type: "Date" },
  ]
  const onSelectedStato = (name: string, value: boolean) => {}

  const setDate = (path: "inizio" | "fine") => (d: Date | null) => {
    if (d) {
      setFiltri(Lens.fromPath<Blocchi>()(["periodo", path]).set(d))
    }
  }
  const checkedStato = (name: string) => false

  const onApplica = () => {
    const provenienza =
      filtri.provenienza.length === 1
        ? filtri.provenienza[0]
        : console.log(filtri.provenienza)

    const filtriModificati = {
      ...filtri,
      provenienza,
      periodo: {
        inizio:
          filtri.periodo.inizio == null
            ? undefined
            : moment(filtri.periodo.inizio).subtract(DATE_OFFSET, "minutes"),
        fine:
          filtri.periodo.fine == null
            ? undefined
            : moment(filtri.periodo.fine).subtract(DATE_OFFSET, "minutes"),
      },
    }
    postUrl("blocchi", filtriModificati).then((_) => {
      close()
      onConfirm(filtri)
    })
  }
  const onReset = () => {
    setFiltri({
      provenienza: ["Tutto"],
      destinazione: [],
      periodo: { inizio: undefined, fine: undefined },
      totale: false,
    })
  }
  return (
    <>
      <div className="is-pulled-right">
        <Icon
          className="is-clickable"
          icon={faTimes}
          onClick={(_) => close()}
        />
      </div>
      <h1 className="is-size-3">BLOCCHI</h1>
      <br />
      <div className="row custom-box-child">
        <label className="custom-container">
          BLOCCO TOTALE
          <input
            type="checkbox"
            checked={filtri.totale}
            onClick={(ev) => {
              setFiltri({ ...filtri, totale: !filtri.totale })
            }}
          />
          <span className="custom-checkmark"></span>
        </label>
      </div>
      <div className="row">
        <SezioneFiltri
          insideModal={true}
          filtri={graficaFiltri}
          checked={{
            destinazione: checkedDestinazione,
            provenienza: checkedProvenienza,
            stato: checkedStato,
          }}
          selected={{
            destinazione: onSelectedDestinazione,
            provenienza: onSelectedProvenienza,
            stato: onSelectedStato,
          }}
          date={{
            inizio: filtri.periodo.inizio || undefined,
            fine: filtri.periodo.fine || undefined,
            onChangeInizio: setDate("inizio"),
            onChangeFine: setDate("fine"),
          }}
          textbox={{
            onChange: (_) => {},
            value: "",
          }}
        />
        <div className="row">
          <button
            className="button is-success is-pulled-right"
            onClick={onApplica}
          >
            Applica
          </button>
        </div>
        <div className="row">
          <button
            className="button is-failure is-pulled-right"
            style={{ marginRight: "10px" }}
            onClick={onReset}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  )
}

const Regola = t.type({
  idRegola: option(t.string),
  destinazione: Destinazione,
  orario: t.type({ ore: t.number, minuti: t.number }),
  giorniAttesa: t.number,
  giorniAttesaSuccessivi: t.number,
})
type Regola = t.TypeOf<typeof Regola>

const asOrario: (x: { ore: number; minuti: number }) => string = ({
  ore,
  minuti,
}) => `${padLeft("00", ore.toString())}:${padLeft("00", minuti.toString())}`
const Regole: React.FC<{ close: () => void; destinazioni: Destinazione[] }> = ({
  close,
  destinazioni,
}) => {
  const regole_: Regola[] = Array<Regola>(25).fill({
    idRegola: "",
    destinazione: "MagazzinoWeb",
    orario: { ore: 14, minuti: 0 },
    giorniAttesa: 0,
    giorniAttesaSuccessivi: 1,
  })
  const destinazioniString = destinazioni.map((x) =>
    typeof x === "string"
      ? x
      : x.punto.nomePunto || `Punto ${x.punto.numeroPunto}`
  )
  const [regole, setRegole] = useState<Regola[]>([])
  useEffect(() => {
    ;(async () => {
      const req = await fetchUrl("regole")
      const json: any[] = await req.json()
      const jsonChanged: unknown = json.map((x) => {
        const orario = moment(x.orario.replace(/\+00:00/, ""))
        return {
          ...x,
          orario: {
            ore: orario.hours(),
            minuti: orario.minutes(),
          },
        }
      })
      const regole = await tPromise.decode(t.array(Regola), jsonChanged)
      if (regole.length === 0) {
        setRegole(regole_)
      } else {
        setRegole(regole)
      }
    })()
  }, [])
  const [rigaInModifica, setRigaInModifica] = useState<number>()
  const [rigaDaModificare, setRigaDaModificare] = useState<Regola>()
  const [isDropdownActive, setActive] = useState(false)
  const [destinazioneSelezionata, setDestinazione] = useState<number>()
  // {
  //   destinazione: "Magazzino WEB",
  //   orario: { ore: 14, minuti: 0 },
  //   giorniAggiornamento: 0,
  //   ordiniSuccessivi: 1
  // }
  const confirmModifica = () => {
    const reg: Regola = {
      ...rigaDaModificare!,
      destinazione: destinazioni[destinazioneSelezionata!],
    }
    const dto: any = {
      ...reg,
      orario: asDate(rigaDaModificare!.orario).format("HH:mm:ss"),
    }
    postUrl("regole", dto).then((res) => {
      if (nuovo) {
        setRegole(regole.concat([reg]))
      } else {
        setRegole([
          ...regole.slice(0, rigaInModifica),
          reg!,
          ...regole.slice(rigaInModifica! + 1),
        ])
      }
      setRigaInModifica(undefined)
      setRigaDaModificare(undefined)
      setActive(false)
    })
  }
  const annullaModifica = () => {
    setActive(false)
    setNuovo(false)
    setRigaInModifica(undefined)
    setRigaDaModificare(undefined)
    setActive(false)
  }
  const eliminaRiga = (i: number) => {
    deleteUrl(`regole/${regole[i].idRegola}`)
      .then((_) => {
        setRegole([...regole.slice(0, i), ...regole.slice(i + 1)])
      })
      .catch(() => {
        setRegole([...regole.slice(0, i), ...regole.slice(i + 1)])
      })
  }
  const modificaRiga = (i: number) => {
    setRigaDaModificare(regole[i])
    setDestinazione(
      destinazioni.findIndex((x) => eqDest(x, regole[i].destinazione))
    )
    setRigaInModifica(i)
  }

  const dropDown = () => {
    return (
      <>
        <div className={`dropdown ${isDropdownActive ? "is-active" : ""}`}>
          <div className="dropdown-trigger">
            <button className="button" onClick={(_) => setActive((x) => !x)}>
              <span>
                {destinazioneSelezionata !== undefined
                  ? destinazioniString[destinazioneSelezionata]
                  : "Destinazione"}
              </span>
              <span className="icon is-small">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu">
            <div className="dropdown-content">
              {destinazioniString.map((d, i) => (
                <a
                  href="#"
                  onClick={(_) => {
                    setDestinazione(i)
                    setActive(false)
                  }}
                  className="dropdown-item"
                >
                  {d}
                </a>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
  const setGiorniAttesa = (g: number) => {
    setRigaDaModificare((r) =>
      r
        ? {
            ...r,
            giorniAttesa: g,
          }
        : r
    )
  }

  const setGiorniAttesaSuccessivi = (g: number) => {
    setRigaDaModificare((r) =>
      r
        ? {
            ...r,
            giorniAttesaSuccessivi: g,
          }
        : r
    )
  }
  const asDate = ({ ore, minuti }: { ore: number; minuti: number }) => {
    const d = new Date(1970, 1, 1, ore, minuti, 0, 0)
    // d.setHours(ore)
    // d.setMinutes(minuti)
    return moment(d)
  }
  const setOrario = (d: Date | null) => {
    if (d) {
      setRigaDaModificare((r) =>
        r
          ? {
              ...r,
              orario: { ore: d.getHours(), minuti: d.getMinutes() },
            }
          : r
      )
    }
  }
  const eqDest = (x: Destinazione, y: Destinazione) => {
    if (x !== "MagazzinoWeb" && y !== "MagazzinoWeb") {
      return (
        x.punto.nomePunto === y.punto.nomePunto &&
        x.punto.numeroPunto === y.punto.numeroPunto
      )
    } else return x === y
  }

  const ref = useRef(null)

  const [nuovo, setNuovo] = useState(false)
  return (
    <>
      <div className="is-pulled-right">
        <Icon
          className="is-clickable"
          icon={faTimes}
          onClick={(_) => close()}
        />
      </div>
      <h1 className="is-size-3">REGOLE</h1>
      <br />
      <section className="modal-section">
        <table
          id="regole"
          className="table is-striped is-bordered is-hoverable"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Destinazione</th>
              <th>Ordini fino a ore</th>
              <th>Giorni aggiornamento</th>
              <th>Ordini successivi</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {regole.map((regola, i) => (
              <tr>
                <td>
                  {i === rigaInModifica
                    ? dropDown()
                    : destinazioniString[
                        destinazioni.findIndex((x) =>
                          eqDest(x, regola.destinazione)
                        )
                      ]}
                </td>
                <td>
                  {i === rigaInModifica ? (
                    <DatePicker
                      className="input"
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="HH:mm"
                      selected={asDate(rigaDaModificare!.orario).toDate()}
                      onChange={(e) => setOrario(e)}
                    />
                  ) : (
                    asOrario(regola.orario)
                  )}
                </td>
                <td>
                  {i === rigaInModifica ? (
                    <input
                      type="number"
                      value={rigaDaModificare!.giorniAttesa}
                      onChange={(e) =>
                        setGiorniAttesa(parseInt(e.target.value))
                      }
                    />
                  ) : (
                    regola.giorniAttesa
                  )}
                </td>
                <td>
                  {i === rigaInModifica ? (
                    <input
                      type="number"
                      value={rigaDaModificare!.giorniAttesaSuccessivi}
                      onChange={(e) =>
                        setGiorniAttesaSuccessivi(parseInt(e.target.value))
                      }
                    />
                  ) : (
                    regola.giorniAttesaSuccessivi
                  )}
                </td>
                <td>
                  <button
                    className="button"
                    id="modificaButton"
                    onClick={(_) =>
                      rigaInModifica !== undefined
                        ? confirmModifica()
                        : modificaRiga(i)
                    }
                    style={{ width: "45px" }}
                  >
                    <Icon
                      icon={rigaInModifica !== undefined ? faCheck : faEdit}
                    />
                  </button>
                  <button
                    className="button"
                    onClick={(_) =>
                      rigaInModifica !== undefined
                        ? annullaModifica()
                        : eliminaRiga(i)
                    }
                    style={{ width: "45px" }}
                  >
                    <Icon icon={faTimes} />
                  </button>
                </td>
              </tr>
            ))}
            {nuovo && (
              <tr>
                <td>{dropDown()}</td>
                <td>
                  <DatePicker
                    className="input"
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    selected={asDate(rigaDaModificare!.orario).toDate()}
                    onChange={(e) => setOrario(e)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rigaDaModificare!.giorniAttesa}
                    onChange={(e) => setGiorniAttesa(parseInt(e.target.value))}
                  />{" "}
                  :
                </td>
                <td>
                  <input
                    type="number"
                    value={rigaDaModificare!.giorniAttesaSuccessivi}
                    onChange={(e) =>
                      setGiorniAttesaSuccessivi(parseInt(e.target.value))
                    }
                  />{" "}
                  :
                </td>
                <td>
                  <button
                    className="button"
                    onClick={(_) => {
                      confirmModifica()
                      setNuovo(false)
                    }}
                    style={{ width: "45px" }}
                  >
                    <Icon icon={faCheck} />
                  </button>
                  <button
                    className="button"
                    onClick={(_) => annullaModifica()}
                    style={{ width: "45px" }}
                  >
                    <Icon icon={faTimes} />
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <button
        style={{ marginTop: "10px" }}
        className="button is-pulled-right is-success"
        onClick={() => {
          setNuovo(true)
          setRigaDaModificare({
            idRegola: "",
            destinazione: "MagazzinoWeb",
            orario: { ore: 12, minuti: 0 },
            giorniAttesa: 0,
            giorniAttesaSuccessivi: 0,
          })
        }}
      >
        Nuovo
      </button>
    </>
  )
}

const OperazioneGenerica = t.type({
  data: t.string,
  codiceRiga: t.string,
})
type OperazioneGenerica = t.TypeOf<typeof OperazioneGenerica>

const OperazioneDTO = t.union([
  t.type({ nuovo: t.string }),
  t.type({ ricevuto: t.string }),
  t.type({ inizioEvasione: t.string }),
  t.type({ inPicking: OperazioneGenerica }),
  t.type({ chiuso: t.string }),
  t.type({ spedito: t.string }),
  t.type({ consegnato: t.string }),
  t.type({ cancellato: OperazioneGenerica }),
])
type OperazioneDTO = t.TypeOf<typeof OperazioneDTO>

const TipoOperazione = t.keyof({
  Nuovo: null,
  Ricevuto: null,
  InizioEvasione: null,
  InPicking: null,
  Chiuso: null,
  Spedito: null,
  Cancellato: null,
})
type TipoOperazione = t.TypeOf<typeof TipoOperazione>

type Operazione =
  | { kind: "nuovo"; value: string }
  | { kind: "ricevuto"; value: string }
  | { kind: "inizioEvasione"; value: string }
  | { kind: "inPicking"; value: OperazioneGenerica }
  | { kind: "chiuso"; value: string }
  | { kind: "spedito"; value: string }
  | { kind: "consegnato"; value: string }
  | { kind: "cancellato"; value: OperazioneGenerica }
function fromDTO(op: OperazioneDTO): Operazione {
  const opAny = op as any
  if (opAny.nuovo) {
    return { kind: "nuovo", value: opAny.nuovo }
  } else if (opAny.ricevuto) {
    return { kind: "ricevuto", value: opAny.ricevuto }
  } else if (opAny.inizioEvasione) {
    return { kind: "inizioEvasione", value: opAny.inizioEvasione }
  } else if (opAny.inPicking) {
    return { kind: "inPicking", value: opAny.inPicking }
  } else if (opAny.chiuso) {
    return { kind: "chiuso", value: opAny.chiuso }
  } else if (opAny.spedito) {
    return { kind: "spedito", value: opAny.spedito }
  } else if (opAny.cancellato) {
    return { kind: "cancellato", value: opAny.cancellato }
  } else if (opAny.consegnato) {
    return { kind: "consegnato", value: opAny.consegnato }
  }
  throw new Error("unreachable, theoretically")
}

const opToJsx = (op: Operazione) => {
  switch (op.kind) {
    case "inPicking":
    case "cancellato": {
      const data = moment(op.value.data)
      const dataFormattata =
        data.get("year") === 1970 ? "" : data.format(DATE_FORMAT)
      return (
        <tr>
          <td>{op.kind}</td>
          <td>{dataFormattata}</td>
          <td>{op.value.codiceRiga}</td>
        </tr>
      )
    }
    default: {
      const data = moment(op.value)
      const dataFormattata =
        data.get("year") === 1970 ? "" : data.format(DATE_FORMAT)
      return (
        <tr>
          <td>{op.kind}</td>
          <td>{dataFormattata}</td>
          <td></td>
        </tr>
      )
    }
  }
}

const LogBody: React.FC<{ codiceOrdine: string; close: () => void }> = ({
  codiceOrdine,
  close,
}) => {
  const [operazioni, setOperazioni] = useState<Operazione[]>([])
  useEffect(() => {
    ;(async () => {
      const ops = await fetchUrl(`operazioni/${codiceOrdine}`)
      const json = await ops.json()
      const parsed = await tPromise.decode(t.array(OperazioneDTO), json)
      setOperazioni(parsed.map(fromDTO))
    })()
  }, [codiceOrdine])
  return (
    <>
      <div className="is-pulled-right">
        <Icon
          className="is-clickable"
          icon={faTimes}
          onClick={(_) => close()}
        />
      </div>
      <h1 className="is-size-3">LOG</h1>
      <br />
      <section className="modal-section">
        <table
          className="table is-striped is-bordered is-hoverable"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Stato</th>
              <th>Data</th>
              <th>Codice Riga</th>
            </tr>
          </thead>
          <tbody>{operazioni.map(opToJsx)}</tbody>
        </table>
      </section>
    </>
  )
}
const IdArticolo = t.type({
  internoZeus: option(t.string),
  pubblicoZeus: option(t.string),
  cartSire: option(t.string),
})
type IdArticolo = t.TypeOf<typeof IdArticolo>
const OrdineRiga = t.type({
  codiceOrdineRiga: t.string,
  idArticolo: IdArticolo,
  descrizioneArticolo: t.string,
  quantitaOrdinata: t.number,
  importo: t.number,
  prezzoOrdinario: t.number,
  prezzoApplicato: t.number,
  statoRiga: option(TipoOperazione),
  note: option(t.string),
})
type OrdineRiga = t.TypeOf<typeof OrdineRiga>

const rigaToJSX = (riga: OrdineRiga) => {
  const descrizioneBreve = riga.descrizioneArticolo.slice(0, 20)
  return (
    <tr>
      <td>{riga.codiceOrdineRiga}</td>
      <td>{riga.idArticolo.internoZeus}</td>
      <td>{riga.idArticolo.pubblicoZeus}</td>
      <td>{riga.idArticolo.cartSire}</td>
      <td>
        <abbr title={riga.descrizioneArticolo}>{descrizioneBreve}</abbr>
      </td>
      <td style={{ textAlign: "right" }}>{riga.quantitaOrdinata}</td>
      <td style={{ textAlign: "right" }}>{formatNumber(riga.importo)}</td>
      <td>{riga.statoRiga}</td>
    </tr>
  )
}
interface ForzaInvioProps {
  showDedalo: boolean
  close: () => void
  codiceOrdine: string
  ultimoForzaInvio: { zeus?: string; dedalo?: string }
}
const ConfermaForzaInvio: React.FC<ForzaInvioProps> = ({
  showDedalo,
  close,
  codiceOrdine,
  ultimoForzaInvio,
}) => {
  const [dedalo, setDedalo] = useState(false)
  const [zeus, setZeus] = useState(false)

  const forzaInvio = (
    <>
      {ultimoForzaInvio.zeus && (
        <>
          <br />
          <p>
            <b>Data e ora ultimo invio forzato ZEUS:</b>{" "}
            {moment(ultimoForzaInvio.zeus).format(DATE_FORMAT)}
          </p>
        </>
      )}
      {ultimoForzaInvio.dedalo !== undefined && showDedalo && (
        <>
          <br />
          <p>
            <b>Data e ora ultimo invio forzato DEDALO:</b>{" "}
            {moment(ultimoForzaInvio.dedalo).format(DATE_FORMAT)}
          </p>
        </>
      )}
    </>
  )
  const bloccoCheckBox = () => {
    if (showDedalo) {
      return (
        <>
          <p>Cliccando su conferma, l'ordine {codiceOrdine} verrà inviato a:</p>
          <br />
          <div className="row">
            <div className="columns">
              <div className="column is-half">
                <label className="custom-container">
                  {" "}
                  DEDALO
                  <input
                    type="checkbox"
                    checked={dedalo}
                    onChange={(_) => setDedalo((x) => !x)}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
              <div className="column is-half">
                <label className="custom-container">
                  {" "}
                  ZEUS
                  <input
                    type="checkbox"
                    checked={zeus}
                    onChange={(_) => setZeus((x) => !x)}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <p>
            Cliccando su conferma, l'ordine {codiceOrdine} verrà inviato a ZEUS{" "}
          </p>
        </>
      )
    }
  }
  const forzaDedalo = async () => {
    return postUrl(`inviodedalo/${codiceOrdine}`, undefined)
  }
  const forzaZeus = async () => {
    return postUrl(`inviozeus/${codiceOrdine}`, undefined)
  }
  const onConferma = () => {
    if (showDedalo) {
      if (dedalo) forzaDedalo()
      if (zeus) forzaZeus()
    } else {
      forzaZeus()
    }
    close()
  }
  return (
    <>
      <div className="is-pulled-right">
        <Icon
          className="is-clickable"
          icon={faTimes}
          onClick={(_) => close()}
        />
      </div>
      <h1 className="is-size-3">FORZA INVIO</h1>
      <br />
      {bloccoCheckBox()}
      {forzaInvio}
      <div className="field is-grouped is-pulled-right button-group-modal">
        <div className="control">
          <button className="button is-success" onClick={onConferma}>
            Conferma
          </button>
        </div>
        <div className="control">
          <button className="button is-danger" onClick={close}>
            Annulla
          </button>
        </div>
      </div>

      {/* <div className="row">
        <button className="button is-success is-pulled-right" onClick={onConferma}>Conferma</button>
        <button className="button is-success is-pulled-right" onClick={close}>Annulla</button>
      </div> */}
    </>
  )
}

const Dettaglio: React.FC<{ codiceOrdine: string; close: () => void }> = ({
  codiceOrdine,
  close,
}) => {
  const [righe, setRighe] = useState<OrdineRiga[]>([])
  useEffect(() => {
    ;(async () => {
      const res = await fetchUrl(`ordini/${codiceOrdine}`)
      const json = await res.json()
      const righe = await tPromise.decode(t.array(OrdineRiga), json.ordineRighe)
      setRighe(righe)
    })()
  }, [codiceOrdine])

  return (
    <>
      <div className="is-pulled-right">
        <Icon
          className="is-clickable"
          icon={faTimes}
          onClick={(_) => close()}
        />
      </div>
      <h1 className="is-size-3">DETTAGLIO</h1>
      <br />
      <section className="modal-section">
        <table
          className="table is-striped is-bordered is-hoverable"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Id Riga</th>
              <th>Cod. Interno ZEUS</th>
              <th>Cod. Esterno ZEUS</th>
              <th>Cod. CART</th>
              <th>Descrizione</th>
              <th>Quantità</th>
              <th>Importo</th>
              <th>Stato</th>
            </tr>
          </thead>
          <tbody>{righe.map(rigaToJSX)}</tbody>
        </table>
      </section>
    </>
  )
}
