import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bulma'
import { useRoutes } from "hookrouter"
import './App.css';
import { Login } from './Components/Login/Login';

const routes = { 
  "/": () => <Login/>,
  "/home": () => <App/>
}

const Index: React.FC = () => {
  const comp = useRoutes(routes)
  return comp || "Not found"
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
