import * as t from "io-ts"
import * as o from "fp-ts/lib/Option"
import * as tPromise from "io-ts-promise"

const isStaging =
  window.location.hostname.includes("staging") ||
  window.location.hostname.includes("localhost")
const urlPrefix = isStaging ? ".staging" : ""
export const KEYCLOAK_LOGIN_ENDPOINT = `https://auth${urlPrefix}.futurelab.cloud/auth/realms/NATURASI/protocol/openid-connect/token`

const exactType: <P extends t.Props>(props: P) => t.ExactC<t.TypeC<P>> = (
  props
) => t.exact(t.type(props))

export const KeycloakResponse = exactType({
  access_token: t.string,
  expires_in: t.number,
  "not-before-policy": t.number,
  refresh_expires_in: t.number,
  refresh_token: t.string,
  scope: t.string,
  session_state: t.string,
  token_type: t.string,
})

type KeycloakResponse = t.TypeOf<typeof KeycloakResponse>

export const checkStatus = (response: Response) => {
  if (response.ok) {
    return response
  } else {
    const err = new Error(response.statusText)
    throw err
  }
}

export const parseJwt = (token: string): o.Option<any> => {
  try {
    if (token !== "") {
      const tmp = JSON.parse(atob(token.split(".")[1]))
      return o.some(tmp)
    }
    return o.none
  } catch {
    return o.none
  }
}

/**
 * Performs a login request using the Keycloak's API
 * @param username username
 * @param password password
 */
export async function login(username: string, password: string) {
  //const secret = isStaging ? "7767cc1b-3238-4d47-8008-0ea62bbfda3b" : ""
  const urlencoded = new URLSearchParams()
  urlencoded.append("client_id", "ordiniecommerce")
  urlencoded.append("username", username)
  urlencoded.append("password", password)
  urlencoded.append("grant_type", "password")
  urlencoded.append("scope", "profile")
  urlencoded.append("response_type", "code")
  //urlencoded.append("client_secret", secret)

  return await fetch(KEYCLOAK_LOGIN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: urlencoded,
  })
}
