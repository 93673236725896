import { useState, useEffect } from "react";

export function useFetch<T>(cb : () => T | PromiseLike<T>) {
  const [v, setV] = useState<T>()
  useEffect(() => {
    const val = Promise.resolve(cb())
    ;(async () => {
      setV(await val);
    })()
  }, [])
  return [v as T, (x : T) => setV(x)] as const
}