import "./Login.css"
import React, { useEffect, useState } from "react"
import { navigate } from "../../Utils/Routing"
import { KeycloakResponse, login, parseJwt } from "../../Utils/Login"
import logo from "../../assets/futurelab_logo.svg"
import * as tPromise from "io-ts-promise"

import * as o from "fp-ts/lib/Option"

const errTxts: Record<string, string> = {
  "401": "Non autorizzato",
  "402": "Errore comune",
}

export const Login: React.FC = () => {
  const [user, setUser] = useState("")
  const [pw, setPw] = useState("")
  const [hasLoggedIn, setLoggedIn] = useState(false)

  const [error, setError] = useState("")

  useEffect(() => {
    document.documentElement.style.backgroundColor = "white"
    document.body.style.backgroundColor = "white"
    document.documentElement.style.fontSize = "10px"
    document.body.style.fontSize = "10px"
    return () => {
      document.documentElement.style.backgroundColor = ""
      document.body.style.backgroundColor = ""
      document.documentElement.style.fontSize = ""
      document.body.style.fontSize = ""
    }
  }, [])

  const execLogin = () => {
    return login(user, pw)
      .then((res) => {
        if (res.ok) {
          return res
        } else {
          const err = new Error(res.status.toString())
          throw err
        }
      })
      .then(async (res) => {
        const deserialized = await tPromise.decode(
          KeycloakResponse,
          await res.json()
        )
        return deserialized.access_token
      })
      .then((token) => {
        const maybeParsedToken = parseJwt(token)
        if (o.isSome(maybeParsedToken)) {
          const parsedToken = maybeParsedToken.value
          // verifico che sia presente la proprietà token, è necessaria per far funzionare correttamente la tdc
          if ("token" in parsedToken && parsedToken.token !== "") {
            localStorage.setItem("access_token", token)
            setLoggedIn(true)
            setError("")
          } else {
            setError("Attributo Keycloak mancante")
          }
        } else {
          setError("Token malformato")
        }
      })
      .catch((err) => {
        console.log(err)
        setError(err.message)
      })
  }

  useEffect(() => {
    if (hasLoggedIn) {
      navigate("/home")
    }
  }, [hasLoggedIn])

  return (
    <form
      action="submit"
      onSubmit={(e) => {
        e.preventDefault()
        execLogin()
      }}
    >
      <div className="login-wrapper">
        <div className="login-logo-wrapper">
          <img
            style={{ marginBottom: 30, width: "100%" }}
            src={logo}
            alt="logo"
            className="login-logo"
          />
        </div>
        <div className="login-input-wrapper">
          <div className="login-input-container">
            <div className="col-3">
              <input
                className="login-input"
                value={user}
                onChange={(x) => setUser(x.target.value)}
                type="text"
                placeholder="Username"
              />
            </div>
          </div>
          <div className="login-input-container">
            <div className="col-3">
              <input
                type="password"
                value={pw}
                onChange={(x) => setPw(x.target.value)}
                className="login-input"
                placeholder="Password"
              />
            </div>
          </div>
          <div className="login-button-container">
            <button className="login-button" type="submit">
              Accedi
            </button>
          </div>
          {error !== "" && (
            <div className="login-input-container">
              <div className="col-3">
                <span className="login-error-message">
                  {errTxts[error] ? errTxts[error] : error}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
